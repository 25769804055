import React, { useState } from "react";
import "./index.scss";
import { Steps } from "antd";
import Step1 from "Step1";
import { Result, Button } from "antd";
import ShareThroughEmail from "ShareThroughEmail";
import WelcomeEmailWithBook from "WelcomeEmailWithBook";
import { CAMPAIGN_PAGE_URL, ShareMethodName } from "Constant";

const { Step } = Steps;

function SharePage(this: any): JSX.Element {
  const [currentStep, setCurrentStep] = useState(0);
  const [choosenShareMethod, setChoosenShareMethod] = useState<ShareMethodName>(
    ShareMethodName.NONE
  );

  const stepsChange = (current: React.SetStateAction<number>) => {
    if (current < currentStep) setCurrentStep(current);
  };

  return (
    <div className="share-page">
      <div className="wrapper">
        <h1 className="text-center">Share 'Coding Hiro' with your friends</h1>
        <section className="">
          <div className="step-wrapper text-center">
            <Steps
              current={currentStep}
              onChange={stepsChange}
              className="site-navigation-steps"
            >
              <Step
                title="Select Sharing Method"
                description="Please click any of the button below to share our kickstarter link first."
              />
              <Step
                title="Input Your Email"
                description="Type your email address which we will send an free e-book to."
              />
              <Step
                title="Done"
                description="The email should arrive at your email in a few minutes"
              />
            </Steps>
          </div>
          <div className="step-content text-center">
            {currentStep === 0 && (
              <Step1
                choosenShareMethod={choosenShareMethod}
                setCurrentStep={setCurrentStep}
                setChoosenShareMethod={setChoosenShareMethod}
              />
            )}
            {currentStep === 1 &&
              (choosenShareMethod === ShareMethodName.EMAIL ? (
                <ShareThroughEmail setCurrentStep={setCurrentStep} />
              ) : (
                <WelcomeEmailWithBook setCurrentStep={setCurrentStep} />
              ))}
            {currentStep === 2 && (
              <Result
                status="success"
                key="final-success-result"
                title="Thanks for sharing!"
                subTitle="A free e-book has been sent to your email."
                extra={[
                  <Button
                    type="primary"
                    role="button"
                    key="go-campaign"
                    onClick={() => window.open(CAMPAIGN_PAGE_URL, "_blank")}
                  >
                    Go Kickstarter Campaign
                  </Button>,

                  <Button
                    key="share-again"
                    role="button"
                    onClick={() => setCurrentStep(0)}
                  >
                    Share Again
                  </Button>,
                ]}
              />
            )}
          </div>
        </section>
      </div>
    </div>
  );
}

export default SharePage;
