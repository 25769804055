import reportWebVitals from "./reportWebVitals";
import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Facebook from "./Facebook";
import Youtube from "./Youtube";
import SharePage from "./SharePage";
import HomePage from "./HomePage";
import TrackingPage from "TrackingPage";
import Email from "./Email";
import EmailB from "./EmailB";
import EmailC from "./EmailC";
import FreeEbook from "FreeEbook";
import Theme from "./Theme";
import { Layout } from "antd";
import ReactGA from "react-ga";
import ttiPolyfill from "tti-polyfill";

ReactGA.initialize("UA-184967932-1");
ttiPolyfill.getFirstConsistentlyInteractive().then((tti) => {
  ReactGA.timing({
    category: "Load Performace",
    variable: "Time to Interactive",
    value: tti || 50000,
  });
});

const { Content } = Layout;

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Theme>
        <Content>
          <Switch>
            <Route path="/" exact>
              <HomePage />
            </Route>
            <Route path="/share" exact>
              <SharePage />
            </Route>
            <Route path="/s/y" exact>
              <Youtube />
            </Route>
            <Route path="/s/f" exact>
              <Facebook />
            </Route>
            <Route path="/s/a" exact>
              <Email />
            </Route>
            <Route path="/s/b" exact>
              <EmailB />
            </Route>
            <Route path="/s/c" exact>
              <EmailC />
            </Route>
            <Route path="/free/ebook" exact>
              <FreeEbook />
            </Route>
            <Route path="/track" exact>
              <TrackingPage />
            </Route>
            <Route>
              <HomePage />
            </Route>
          </Switch>
        </Content>
      </Theme>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
