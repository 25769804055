import React from 'react'
import './index.scss'
import { Row, Col } from 'antd'
import { Typography } from 'antd'
import characters from './chars.png'
import obama from './obama.png'
import mainCover from './main-cover.jpg'
import openPages from './open-pages.png'
import { CAMPAIGN_PAGE_URL } from '../Constant'
const { Title } = Typography

function HomePage(): JSX.Element {
	return (
		<div className="HomePage">
			<section className="hero">
				<div className="hero-wrapper">
					<Row>
						<Col span={24}>
							<img src={characters} alt="" width="100%" />
						</Col>
						<Col span={24}>
							<div className="shoutout">
								<Title level={2}>Coding Hiro: The Gems of Iris</Title>
								<p>
									A children's book that teaches fundamental coding concepts through an adventurous
									and magical story.
								</p>
								<a className="hero-button" href="/track">
									Track Order
								</a>
							</div>
						</Col>
					</Row>
				</div>
			</section>
			<div className="section">
				<div className="container">
					<Row>
						<Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24} className="video">
							<iframe
								width="100%"
								height="100%"
								title="Coding Hiro Book for Kids"
								src="https://www.youtube.com/embed/hgpLR3r3jw8"
								frameBorder="0"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
								allowFullScreen></iframe>
						</Col>
						<Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
							<div className="content">
								<Title level={2} className="section-title">
									The story
								</Title>
								<p>
									This is a story about a little girl, Emma, who found a magical board game -{' '}
									<em>“The Gems of Iris”</em> on her way home. When she opened the box, she was pulled
									into this board game and found herself in a room with Hiro, a funny looking creature
									wearing a cape.
								</p>
								<p>
									In order for Emma to get home, she had to collect seven (7) gems from seven (7)
									different magical locations. There is a challenge waiting for Emma at each location,
									and she would need to use a coding concept to solve the challenge and receive a gem.
								</p>
								<p>
									Hiro decided to offer his help to Emma on her adventure. Along the way, Hiro and
									Emma helped imps in the crystal caves, played with fairies in the magic forest,
									aided a mermaid princess in Atlantis, and flew with the fearless dragons... Well, if
									you like to know the rest of the story, we need your help.
								</p>
							</div>
						</Col>
					</Row>
				</div>
			</div>
			<div className="quote-section">
				<div className="quote-wrapper">
					<p className="quote">
						In the new economy, computer science isn't an optional skill - it's a basic skill.
					</p>
					<p className="obama">
						<img src={obama} alt="barack-obama-loves-coding-hiro" />
						<span className="name">Barack Obama</span>
					</p>
				</div>
			</div>
			<div className="section">
				<div className="container">
					<Row>
						<Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
							<img src={openPages} alt="Coding Hiro" width="100%" />
						</Col>
						<Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
							<div className="content">
								<Title level={2}>Support us on Kickstarter</Title>
								<p>
									Coding is undeniably a necessary literacy in this technological world. Understanding
									the basics of coding helps our children to solve problems logically and creatively.
									So how can we inspire our children to learn basic coding concepts?
								</p>
								<p>
									The answer is <em>"storytelling"</em>.
								</p>
								<p>
									Over the last 2 years, we've been working on this story, and now this story is
									ready. We need your help to print the first 1,000 copies, get 2 ISBNs, make final
									edit on the illustration. For us to bring this book to life, please support us on
									Kickstarter. Back us now!{' '}
								</p>
								<a href={CAMPAIGN_PAGE_URL} className="nav-button nav-kickstarter">
									See us on Kickstarter
								</a>
							</div>
						</Col>
					</Row>
				</div>
			</div>
			<div className="section-cta">
				<div className="hero-wrapper">
					<Row>
						<Col span={24}>
							<img src={mainCover} alt="coding-hiro" width="70%" />
						</Col>
						<Col span={24}>
							<div className="shoutout">
								<Title level={3} className="section-title">
									Limited Time
								</Title>
								<p>
									Our campaign on Kickstarter is available until <em>January 7, 2021</em>.
								</p>
								<a className="hero-button" href={CAMPAIGN_PAGE_URL}>
									Pre-Order
								</a>
							</div>
						</Col>
					</Row>
				</div>
			</div>
		</div>
	)
}

export default HomePage
