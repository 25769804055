import React, { FunctionComponent } from 'react'
import { CAMPAIGN_PAGE_URL } from '../Constant'
import logo from './coding-hiro-logo.png'
import './grid.scss'
import './index.scss'

const Theme: FunctionComponent = props => (
	<div className="theme">
		<div className="nav-wrapper">
			<a href="/" className="brand">
				<img src={logo} alt="Coding Hiro" />
			</a>
			<a className="nav-button nav-share" href={'/share'}>
				Share
			</a>
			{/* <a className="nav-button nav-cta" href={CAMPAIGN_PAGE_URL}>
				Pre-Order
			</a> */}
			<a className="nav-button nav-cta" href="/track">
				Track Order
			</a>
		</div>
		<div className="body">
			{props.children}
			<div className="footer">&copy; 2020 Coding Hiro</div>
		</div>
	</div>
)

export default Theme
