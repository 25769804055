import React, { useCallback, useState } from "react";
import { Alert, Button, Form, Input, Spin } from "antd";
import jsonp from "jsonp";
import "./index.scss";
import { WELCOME_API } from "../Constant";
import PropTypes, { InferProps } from "prop-types";

interface EmailSubmission {
  FNAME: string;
  EMAIL: string;
  YFE: string;
}

function WelcomeEmailWithBook({
  setCurrentStep,
}: InferProps<typeof WelcomeEmailWithBook.propTypes>): JSX.Element {
  const [form] = Form.useForm();
  const [welcomeFormMsg, setWelcomeFormMsg] = useState("");
  const [
    welcomeFormSubmissionStatus,
    setWelcomeFormSubmissionStatus,
  ] = useState(false);

  const [loading, setLoading] = useState(false);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 24,
        offset: 8,
      },
    },
  };

  const onFinish = useCallback(
    async (values: EmailSubmission) => {
      setLoading(true);

      // sending welcome package
      let processedValue = [];
      for (const [key, value] of Object.entries(values)) {
        processedValue.push(`${key}=${encodeURIComponent(value)}`);
      }
      const path = `${WELCOME_API}&${processedValue.join("&")}`;
      await new Promise((resolve, reject) => {
        jsonp(
          path.replace("/post?", "/post-json?"),
          { param: "c" },
          (err: any, data: { msg: string | string[]; result: string }) => {
            setLoading(false);
            if (data.msg.includes("already subscribed")) {
              setWelcomeFormMsg(`${values.EMAIL} is already subscribed.`);
              setWelcomeFormSubmissionStatus(false);
              resolve(data.msg);
            } else if (err) {
              setWelcomeFormMsg(`Something went wrong, please try again.`);
              setWelcomeFormSubmissionStatus(false);
              reject(err);
            } else if (data.result !== "success") {
              setWelcomeFormMsg(
                `Something went wrong, please try again with another email address.`
              );
              setWelcomeFormSubmissionStatus(false);
              resolve(data.msg);
            } else {
              setWelcomeFormMsg(
                `An welcome email will send to you with a free e-book.`
              );
              setWelcomeFormSubmissionStatus(true);
              setCurrentStep(2);
              resolve(data.msg);
            }
          }
        );
      });
    },
    [setCurrentStep]
  );

  return (
    <div className="share-with-email">
      <h2 className="text-left">Get a free e-book</h2>
      <Spin tip="Loading..." spinning={loading}>
        <div className="text-left" style={{ marginBottom: "1rem" }}>
          {welcomeFormMsg && (
            <Alert
              style={{ marginTop: "1rem" }}
              message={welcomeFormSubmissionStatus ? "Successed" : "Error"}
              description={welcomeFormMsg}
              type={welcomeFormSubmissionStatus ? "success" : "error"}
              showIcon
              closable
            />
          )}
        </div>
        <Form
          form={form}
          {...formItemLayout}
          name="share-with-email"
          onFinish={onFinish}
        >
          <Form.Item
            label="Your name"
            name="FNAME"
            rules={[{ required: true, message: "Please input your name!" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="EMAIL"
            label="Email"
            rules={[
              {
                type: "email",
                required: true,
                message: "Please input your email address!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item {...tailFormItemLayout}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
}

WelcomeEmailWithBook.propTypes = {
  setCurrentStep: PropTypes.func.isRequired,
};

export default WelcomeEmailWithBook;
