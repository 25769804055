export const userData = [
    {
        delieveryStatus: "Delivered",
        delivered: true,
        internalTrackingNum: "CH-ZEtnZ",
        thirdPartyTrackNum: "GE542612492CN",
        displayAddress: "Watsonia\nAustralia",
        deliveryHistory: [
            {title: "CodingHiro Warehouse (China), packed in warehouse and sending out", timestamp: "2021-06-12 13:59"},
            {title: "Shanghai-Chongming, Shipment received at first TNT location", timestamp: "2021-06-18 23:10"},
            {title: "Shipment now at the depot nearest to collection address", timestamp: "2021-06-19 00:31"},
            {title: "Pudong International Airport, Shipment arrived at connection point", timestamp: "2021-06-19 02:33"},
            {title: "Tullamarine, Shipment is out for delivery.", timestamp: "2021-06-21 08:50"},
            {title: "Watsonia, Shipment delivered in good condition", timestamp: "2021-06-21 10:36"},
        ]
    },
    {
        delieveryStatus: "Delivered",
        delivered: true,
        internalTrackingNum: "CH-hjdoi",
        thirdPartyTrackNum: "GE542610704CN",
        displayAddress: "St Kilda\nAustralia",
        deliveryHistory: [
            {title: "CodingHiro Warehouse (China), packed in warehouse and sending out", timestamp: "2021-06-12 13:59"},
            {title: "Shanghai-Chongming, Shipment received at first TNT location", timestamp: "2021-06-18 23:10"},
            {title: "Shipment now at the depot nearest to collection address", timestamp: "2021-06-19 00:31"},
            {title: "Pudong International Airport, Shipment arrived at connection point", timestamp: "2021-06-19 02:33"},
            {title: "Tullamarine, Shipment is out for delivery.", timestamp: "2021-06-21 08:50"},
            {title: "St Kilda, Shipment delivered in good condition", timestamp: "2021-06-21 10:51"},
        ]
    },
    {
        delieveryStatus: "Delivered",
        delivered: true,
        internalTrackingNum: "CH-dxhCU",
        thirdPartyTrackNum: "GE542612444CN",
        displayAddress: "Templestowe Lower\nAustralia",
        deliveryHistory: [
            {title: "CodingHiro Warehouse (China), packed in warehouse and sending out", timestamp: "2021-06-12 13:59"},
            {title: "Shanghai-Chongming, Shipment received at first TNT location", timestamp: "2021-06-18 23:10"},
            {title: "Shipment now at the depot nearest to collection address", timestamp: "2021-06-19 00:31"},
            {title: "Pudong International Airport, Shipment arrived at connection point", timestamp: "2021-06-19 02:33"},
            {title: "Tullamarine, Shipment is out for delivery.", timestamp: "2021-06-21 08:50"},
            {title: "Templestowe Lower, Shipment delivered in good condition", timestamp: "2021-06-21 10:37"},
        ]
    },
    {
        delieveryStatus: "In Progress",
        delivered: false,
        internalTrackingNum: "CH-vc1LD",
        thirdPartyTrackNum: "GE542612400CN",
        displayAddress: "Heathridge\nAustralia",
        deliveryHistory: [
            {title: "CodingHiro Warehouse (China), packed in warehouse and sending out", timestamp: "2021-06-12 13:59"},
            {title: "Shanghai-Chongming, Shipment received at first TNT location", timestamp: "2021-06-18 23:10"},
            {title: "Shipment now at the depot nearest to collection address", timestamp: "2021-06-19 00:31"},
            {title: "Pudong International Airport, Shipment arrived at connection point", timestamp: "2021-06-19 02:33"},
            {title: "Singapore, Shipment arrived at connection point", timestamp: "2021-06-19 20:30"},
            {title: "Singapore, There's been a delay but we're working hard to deliver on time", timestamp: "2021-06-20 06:14"},
            {title: "Welshpool, Shipment being processed at TNT location.", timestamp: "2021-06-23 05:46"},
        ]
    },
    {
        delieveryStatus: "In Progress",
        delivered: false,
        internalTrackingNum: "CH-9IGwG",
        thirdPartyTrackNum: "GE542612360CN",
        displayAddress: "Balwyn\nAustralia",
        deliveryHistory: [
            {title: "CodingHiro Warehouse (China), packed in warehouse and sending out", timestamp: "2021-06-12 13:59"},
            {title: "Shanghai-Chongming, Shipment received at first TNT location", timestamp: "2021-06-18 23:10"},
            {title: "Shipment now at the depot nearest to collection address", timestamp: "2021-06-19 00:31"},
            {title: "Pudong International Airport, Shipment arrived at connection point", timestamp: "2021-06-19 02:33"},
            {title: "Tullamarine, Shipment arrived at TNT location", timestamp: "2021-06-22 14:53"},
            {title: "Tullamarine, Shipment now at depot nearest to delivery address", timestamp: "2021-06-23 07:42"},
            {title: "Tullamarine, Shipment is out for delivery.", timestamp: "2021-06-23 08:18"},
        ]
    },
    {
        delieveryStatus: "Delivered",
        delivered: true,
        internalTrackingNum: "CH-ARhn5",
        thirdPartyTrackNum: "GE542612342CN",
        displayAddress: "Fawkner\nAustralia",
        deliveryHistory: [
            {title: "CodingHiro Warehouse (China), packed in warehouse and sending out", timestamp: "2021-06-12 13:59"},
            {title: "Shanghai-Chongming, Shipment received at first TNT location", timestamp: "2021-06-18 23:10"},
            {title: "Shipment now at the depot nearest to collection address", timestamp: "2021-06-19 00:31"},
            {title: "Pudong International Airport, Shipment arrived at connection point", timestamp: "2021-06-19 02:29"},
            {title: "Tullamarine, Shipment arrived at TNT location", timestamp: "2021-06-22 07:44"},
            {title: "Tullamarine, Shipment now at depot nearest to delivery address", timestamp: "2021-06-22 07:56"},
            {title: "Fawkner, Shipment delivered in good condition", timestamp: "2021-06-22 12:07"},
        ]
    },
    {
        delieveryStatus: "Delivered",
        delivered: true,
        internalTrackingNum: "CH-WDnLN",
        thirdPartyTrackNum: "GE542612271CN",
        displayAddress: "Flinders Park\nAustralia",
        deliveryHistory: [
            {title: "CodingHiro Warehouse (China), packed in warehouse and sending out", timestamp: "2021-06-12 13:59"},
            {title: "Shanghai-Chongming, Shipment received at first TNT location", timestamp: "2021-06-18 23:10"},
            {title: "Shipment now at the depot nearest to collection address", timestamp: "2021-06-19 00:31"},
            {title: "Pudong International Airport, Shipment arrived at connection point", timestamp: "2021-06-19 02:29"},
            {title: "Adelaide Airport, Shipment now at depot nearest to delivery address", timestamp: "2021-06-22 07:59"},
            {title: "Flinders Park, Shipment delivered in good condition", timestamp: "2021-06-22 12:32"},
        ]
    },
    {
        delieveryStatus: "Delivered",
        delivered: true,
        internalTrackingNum: "CH-nc8Fi",
        thirdPartyTrackNum: "280486538831",
        displayAddress: "Sharpsburg\nUNITED STATES",
        deliveryHistory: [
            {title: "CodingHiro Warehouse (China), packed in warehouse and sending out", timestamp: "2021-06-12 13:59"},
            {title: "Shipment information sent to FedExdfa", timestamp: "2021-06-17 05:34"},
            {title: "SHANGHAI CN, At local FedEx facility", timestamp: "2021-06-19 23:09"},
            {title: "MEMPHIS, TN, Arrived at FedEx location", timestamp: "2021-06-20 07:40"},
            {title: "SENNAN-SHI JP, In transit", timestamp: "2021-06-20 09:44"},
            {title: "ATLANTA, GA, At destination sort facility", timestamp: "2021-06-20 19:07"},
            {title: "SHARPSBURG, GA, Delivered, Left on porch. Package delivered to recipient address - release authorized", timestamp: "2021-06-21 11:13"},
        ]
    },
    {
        delieveryStatus: "In Progress",
        delivered: false,
        internalTrackingNum: "CH-TYFlu",
        thirdPartyTrackNum: "1Z3X19526700364331",
        displayAddress: "Toronto\nCANADA",
        deliveryHistory: [
            {title: "CodingHiro Warehouse (China), packed in warehouse and sending out", timestamp: "2021-06-12 13:59"},
            {timestamp: "2021-06-17 19:08", title: "China, Shipper created a label, UPS has not received the package yet."},
            {timestamp: "2021-06-19 16:29", title: "Shanghai, China, Pickup Scan"},
            {timestamp: "2021-06-19 18:31", title: "Shanghai, China, Export Scan"},
            {timestamp: "2021-06-19 19:00", title: "Shanghai, China, Departed from Facility"},
            {timestamp: "2021-06-19 19:15", title: "Shanghai, China, Arrived at Facility"},
            {timestamp: "2021-06-19 21:18", title: "Shanghai, China, Export Scan"},
            {timestamp: "2021-06-22 15:47", title: "Shanghai, China, Your package is in transit. We're updating plans to schedule your delivery."},
            {timestamp: "2021-06-22 16:05", title: "Shanghai, China, Your package is in transit. We're updating plans to schedule your delivery. / The package will be forwarded to a UPS facility in the destination city."},
            {timestamp: "2021-06-22 16:57", title: "Shanghai, China, Departed from Facility"},
            {timestamp: "2021-06-22 20:14", title: "Narita, Japan, Arrived at Facility"},
        ]
    },
    {
        delieveryStatus: "Delivered",
        delivered: true,
        internalTrackingNum: "CH-zW9kk",
        thirdPartyTrackNum: "280486585182",
        displayAddress: "San Jose\nUNITED STATES",
        deliveryHistory: [
            {title: "CodingHiro Warehouse (China), packed in warehouse and sending out", timestamp: "2021-06-12 13:59"},
            {title: "Shipment information sent to FedEx", timestamp: "2021-06-17 05:37"},
            {title: "SHANGHAI CN, At local FedEx facility", timestamp: "2021-06-18 23:10"},
            {title: "ANCHORAGE, AK, Departed FedEx location", timestamp: "2021-06-19 19:08"},
            {title: "MEMPHIS, TN, Arrived at FedEx location", timestamp: "2021-06-20 03:52"},
            {title: "OAKLAND, CA, Arrived at FedEx location", timestamp: "2021-06-20 17:04"},
            {title: "SAN JOSE, CA, Delivered, Package delivered to recipient address - release authorized", timestamp: "2021-06-21 09:13"},
        ]
    },
    {
        delieveryStatus: "In Progress",
        delivered: false,
        internalTrackingNum: "CH-20Mez",
        thirdPartyTrackNum: "280486616531",
        displayAddress: "San Antonio\nUNITED STATES",
        deliveryHistory: [
            {title: "CodingHiro Warehouse (China), packed in warehouse and sending out", timestamp: "2021-06-12 13:59"},
            {title: "Shipment information sent to FedEx", timestamp: "2021-06-17 05:39"},
            {title: "SHANGHAI CN, At local FedEx facility", timestamp: "2021-06-18 23:10"},
            {title: "MEMPHIS, TN, Arrived at FedEx location", timestamp: "2021-06-20 07:40"},
            {title: "SENNAN-SHI JP, In transit", timestamp: "2021-06-20 09:44"},
        ]
    },
    {
        delieveryStatus: "Delivered",
        delivered: true,
        internalTrackingNum: "CH-qInaS",
        thirdPartyTrackNum: "GE542612237CN",
        displayAddress: "Ivanhoe\nAustralia",
        deliveryHistory: [
            {title: "CodingHiro Warehouse (China), packed in warehouse and sending out", timestamp: "2021-06-12 13:59"},
            {title: "Shanghai-Chongming, Shipment received at first TNT location", timestamp: "2021-06-18 23:10"},
            {title: "Shipment now at the depot nearest to collection address", timestamp: "2021-06-19 00:31"},
            {title: "Pudong International Airport, Shipment arrived at connection point", timestamp: "2021-06-19 02:33"},
            {title: "Tullamarine, Shipment is out for delivery.", timestamp: "2021-06-21 08:50"},
            {title: "Ivanhoe, Shipment delivered in good condition", timestamp: "2021-06-21 17:20"},
        ]
    },
    {
        delieveryStatus: "In Progress",
        delivered: false,
        internalTrackingNum: "CH-O3GQ5",
        thirdPartyTrackNum: "280486973672",
        displayAddress: "miri sarawak\nMALAYSIA",
        deliveryHistory: [
            {title: "CodingHiro Warehouse (China), packed in warehouse and sending out", timestamp: "2021-06-12 13:59"},
            {title: "Shipment information sent to FedEx", timestamp: "2021-06-17 06:01"},
            {title: "SHANGHAI CN, At FedEx origin facility", timestamp: "2021-06-18 19:54"},
            {title: "SENNAN-SHI JP, In transit", timestamp: "2021-06-22 18:57"},
            {title: "HANOI VN, In transit", timestamp: "2021-06-22 23:12"},
            {title: "GUANGZHOU CN, Departed FedEx location", timestamp: "2021-06-23 06:14"},
        ]
    },
    {
        delieveryStatus: "In Progress",
        delivered: false,
        internalTrackingNum: "CH-COnRE",
        thirdPartyTrackNum: "280486653021",
        displayAddress: "Merrilville\nUNITED STATES",
        deliveryHistory: [
            {title: "CodingHiro Warehouse (China), packed in warehouse and sending out", timestamp: "2021-06-12 13:59"},
            {title: "Shipment information sent to FedEx", timestamp: "2021-06-17 05:43"},
            {title: "SHANGHAI CN, Left FedEx origin facility", timestamp: "2021-06-18 21:04"},
            {title: "MEMPHIS, TN, Arrived at FedEx location", timestamp: "2021-06-20 07:40"},
        ]
    },
    {
        delieveryStatus: "Delivered",
        delivered: true,
        internalTrackingNum: "CH-nRVBU",
        thirdPartyTrackNum: "280486676537",
        displayAddress: "Grand Rapids\nUNITED STATES",
        deliveryHistory: [
            {title: "CodingHiro Warehouse (China), packed in warehouse and sending out", timestamp: "2021-06-12 13:59"},
            {title: "Shipment information sent to FedEx", timestamp: "2021-06-17 05:43"},
            {title: "SHANGHAI CN, Left FedEx origin facility", timestamp: "2021-06-18 21:04"},
            {title: "MEMPHIS, TN, Arrived at FedEx location", timestamp: "2021-06-20 07:40"},
            {title: "SENNAN-SHI JP, In transit", timestamp: "2021-06-20 09:44"},
            {title: "KENTWOOD, MI, On FedEx vehicle for delivery", timestamp: "2021-06-21 10:58"},
            {title: "GRAND RAPIDS, MI, Delivered, Package delivered to recipient address - release authorized", timestamp: "2021-06-21 11:52"},
        ]
    },
    {
        delieveryStatus: "Delivered",
        delivered: true,
        internalTrackingNum: "CH-Pauv8",
        thirdPartyTrackNum: "GE542614547CN",
        displayAddress: "Point Cook\nAustralia",
        deliveryHistory: [
            {title: "CodingHiro Warehouse (China), packed in warehouse and sending out", timestamp: "2021-06-12 13:59"},
            {title: "Shanghai-Chongming, Shipment received at first TNT location", timestamp: "2021-06-18 23:10"},
            {title: "Shipment now at the depot nearest to collection address", timestamp: "2021-06-19 00:31"},
            {title: "Pudong International Airport, Shipment arrived at connection point", timestamp: "2021-06-19 02:33"},
            {title: "Tullamarine, Shipment is out for delivery.", timestamp: "2021-06-22 08:50"},
            {title: "Point Cook, Shipment delivered in good condition", timestamp: "2021-06-22 16:20"},
        ]
    },
    {
        delieveryStatus: "Delivered",
        delivered: true,
        internalTrackingNum: "CH-cyIrT",
        thirdPartyTrackNum: "280486712068",
        displayAddress: "San Pablo\nUNITED STATES",
        deliveryHistory: [
            {title: "CodingHiro Warehouse (China), packed in warehouse and sending out", timestamp: "2021-06-12 13:59"},
            {title: "Shipment information sent to FedEx", timestamp: "2021-06-17 05:45"},
            {title: "SHANGHAI CN, At local FedEx facilityHOU", timestamp: "2021-06-18 23:10"},
            {title: "MEMPHIS, TN, Arrived at FedEx location", timestamp: "2021-06-19 12:57"},
            {title: "OAKLAND, CA, Arrived at FedEx location", timestamp: "2021-06-20 20:10"},
            {title: "EMERYVILLE, CA, On FedEx vehicle for delivery", timestamp: "2021-06-21 07:08"},
            {title: "SAN PABLO, CA, Delivered, Package delivered to recipient address - release authorized", timestamp: "2021-06-21 09:36"},
        ]
    },
    {
        delieveryStatus: "Delivered",
        delivered: true,
        internalTrackingNum: "CH-HJ4W6",
        thirdPartyTrackNum: "280486751010",
        displayAddress: "Brooklyn\nUNITED STATES",
        deliveryHistory: [
            {title: "CodingHiro Warehouse (China), packed in warehouse and sending out", timestamp: "2021-06-12 13:59"},
            {title: "Shipment information sent to FedEx", timestamp: "2021-06-17 05:45"},
            {title: "SHANGHAI CN, At local FedEx facilityHOU", timestamp: "2021-06-18 23:10"},
            {title: "MEMPHIS, TN, Arrived at FedEx location", timestamp: "2021-06-19 12:57"},
            {title: "JAMAICA, NY, At destination sort facility", timestamp: "2021-06-20 18:28"},
            {title: "MASPETH, NY, On FedEx vehicle for delivery", timestamp: "2021-06-21 08:05"},
            {title: "BROOKLYN, NY, Delivered, Package delivered to recipient address - release authorized", timestamp: "2021-06-21 10:50"},
        ]
    },
    {
        delieveryStatus: "In Progress",
        delivered: false,
        internalTrackingNum: "CH-nN35F",
        thirdPartyTrackNum: "GE542612197CN",
        displayAddress: "Te Awamutu\nNEW ZEALAND",
        deliveryHistory: [
            {title: "CodingHiro Warehouse (China), packed in warehouse and sending out", timestamp: "2021-06-12 13:59"},
            {title: "Shanghai-Chongming, Shipment received at first TNT location.", timestamp: "2021-06-18 23:14"},
            {title: "Pudong International Airport, There's been a delay but we're working hard to deliver on time", timestamp: "2021-06-19 02:18"},
            {title: "Held awaiting customs clearance. We have no influence on this process.", timestamp: "2021-06-19 07:35"},
            {title: "Pudong International Airport, Shipment in transit", timestamp: "2021-06-22 03:22"},
            {title: "Customs has released the goods", timestamp: "2021-06-22 19:29"},
        ]
    },
    {
        delieveryStatus: "In Progress",
        delivered: false,
        internalTrackingNum: "CH-ctGgv",
        thirdPartyTrackNum: "280486911766",
        displayAddress: "Chandlers Ford\nUNITED KINGDOM",
        deliveryHistory: [
            {title: "CodingHiro Warehouse (China), packed in warehouse and sending out", timestamp: "2021-06-12 13:59"},
            {title: "Shipment information sent to FedEx", timestamp: "2021-06-17 05:57"},
            {title: "SHANGHAI CN, Left FedEx origin facility", timestamp: "2021-06-19 19:26"},
            {title: "SHANGHAI CN, International shipment release - Export", timestamp: "2021-06-23 03:30"},
            {title: "SHANGHAI CN, In transit", timestamp: "2021-06-23 04:48"},
        ]
    },
    {
        delieveryStatus: "In Progress",
        delivered: false,
        internalTrackingNum: "CH-Li5to",
        thirdPartyTrackNum: "GE542612325CN",
        displayAddress: "Robinvale\nAustralia",
        deliveryHistory: [
            {title: "CodingHiro Warehouse (China), packed in warehouse and sending out", timestamp: "2021-06-12 13:59"},
            {title: "Shanghai-Chongming, Shipment received at first TNT location", timestamp: "2021-06-18 23:10"},
            {title: "Shipment now at the depot nearest to collection address", timestamp: "2021-06-19 00:31"},
            {title: "Pudong International Airport, Shipment arrived at connection point", timestamp: "2021-06-19 02:33"},
            {title: "Tullamarine, Shipment is out for delivery.", timestamp: "2021-06-21 08:50"},
            {title: "Robinvale, Receiver not home. We've left them a message and we're waiting for them to get in contact with us.", timestamp: "2021-06-22 12:00"},
        ]
    },
    {
        delieveryStatus: "In Progress",
        delivered: false,
        internalTrackingNum: "CH-0Mj5z",
        thirdPartyTrackNum: "1Z3X19526711996343",
        displayAddress: "Richmond Hill\nCANADA",
        deliveryHistory: [
            {title: "CodingHiro Warehouse (China), packed in warehouse and sending out", timestamp: "2021-06-12 13:59"},
            {timestamp: "2021-06-17 19:08", title: "China, Shipper created a label, UPS has not received the package yet."},
            {timestamp: "2021-06-19 16:29", title: "Shanghai, China, Pickup Scan"},
            {timestamp: "2021-06-19 18:31", title: "Shanghai, China, Export Scan"},
            {timestamp: "2021-06-19 19:00", title: "Shanghai, China, Departed from Facility"},
            {timestamp: "2021-06-19 19:15", title: "Shanghai, China, Arrived at Facility"},
            {timestamp: "2021-06-19 21:18", title: "Shanghai, China, Export Scan"},
            {timestamp: "2021-06-22 15:47", title: "Shanghai, China, Your package is in transit. We're updating plans to schedule your delivery."},
            {timestamp: "2021-06-22 16:05", title: "Shanghai, China, Your package is in transit. We're updating plans to schedule your delivery. / The package will be forwarded to a UPS facility in the destination city."},
            {timestamp: "2021-06-22 16:57", title: "Shanghai, China, Departed from Facility"},
            {timestamp: "2021-06-22 20:14", title: "Narita, Japan, Arrived at Facility"},
        ]
    },
    {
        delieveryStatus: "In Progress",
        delivered: false,
        internalTrackingNum: "CH-lGSyL",
        thirdPartyTrackNum: "SF1125846029588",
        displayAddress: "HONG KONG",
        deliveryHistory: [
            {title: "CodingHiro Warehouse (China), packed in warehouse and sending out", timestamp: "2021-06-12 13:59"},
            {title: "020WLL, 【020WLL】 Shipment arrive at 020WLL distribution center", timestamp: "2021-06-18 20:13"},
            {title: "510WJ, 【510WJ】 Shipment arrive at 510WJ distribution center", timestamp: "2021-06-18 20:55"},
            {title: "021WJ, 【021WJ】 Shipment arrive at 021WJ distribution center", timestamp: "2021-06-18 21:40"},
            {title: "021HC, 【021HC】 S.F. Express has picked up the shipment", timestamp: "2021-06-19 03:22"},
            {title: "SHENZHEN CITY, 【SHENZHEN CITY】 Shipment arrive at SHENZHEN CITY distribution center", timestamp: "2021-06-21 02:01"},
            {title: "HONGKONG CHINA, 【HONGKONG CHINA】 Shipment transiting to next station", timestamp: "2021-06-21 17:33"},
            {title: "852DB, 【852DB】 Shipment arrive at 852DB distribution center", timestamp: "2021-06-21 18:04"},
        ]
    },
    {
        delieveryStatus: "In Progress",
        delivered: false,
        internalTrackingNum: "CH-P3LRf",
        thirdPartyTrackNum: "280486376561",
        displayAddress: "Selangor\nMalaysia",
        deliveryHistory: [
            {title: "CodingHiro Warehouse (China), packed in warehouse and sending out", timestamp: "2021-06-12 13:59"},
            {title: "Shipment information sent to FedEx", timestamp: "2021-06-17 06:01"},
            {title: "SHANGHAI CN, At FedEx origin facility", timestamp: "2021-06-18 19:54"},
            {title: "SENNAN-SHI JP, In transit", timestamp: "2021-06-22 18:57"},
            {title: "HANOI VN, In transit", timestamp: "2021-06-22 23:12"},
            {title: "GUANGZHOU CN, Departed FedEx location", timestamp: "2021-06-23 06:14"},
        ]
    },
];