export enum ShareMethodName {
  FACEBOOK = "facebook",
  YOUTUBE = "youtube",
  TWITTER = "twitter",
  EMAIL = "email",
  NONE = "",
}

export const WELCOME_API =
  process.env.REACT_APP_WELCOME_API ||
  "https://codinghiro.us7.list-manage.com/subscribe/post?u=daca52c9037a300214d2ca658&id=0ac06f84b3";

export const REFERRAL_API =
  process.env.REACT_APP_REERRAL_API ||
  "https://codinghiro.us7.list-manage.com/subscribe/post?u=4b8ec8c8064628ba3a79da0b5&amp;id=fb2df61755";

export const CAMPAIGN_PAGE_URL =
  process.env.REACT_APP_CAMPAIGN_PAGE_URL ||
  "https://www.kickstarter.com/projects/mkc/237827347";

export const FACEBOOK_SHARE_URL =
  "https://facebook.com/sharer/sharer.php?u=" + CAMPAIGN_PAGE_URL;

export const TWITTER_SHARE_URL =
  "https://twitter.com/intent/tweet/?text=This%20is%20the%20Coding%20Hiro%20book%20pre-order%20link%20for%20your%20kids.&amp;url=" +
  CAMPAIGN_PAGE_URL;

export const EMAIL_SHARE_URL =
  "mailto:?subject=This%20is%20Coding%20Hiro%20book%20pre-order%20link%20for%20your%20kids.&amp;body=" +
  CAMPAIGN_PAGE_URL;
