import WelcomeEmailWithBook from "WelcomeEmailWithBook";
import "./index.scss";
import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";

function FreeEbook(this: any): JSX.Element {
  const location = useLocation();
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
  const setCurrentStep = (id: number) => {};
  return (
    <>
      <div className="free-ebook-wrapper">
        <WelcomeEmailWithBook setCurrentStep={setCurrentStep} />
      </div>
    </>
  );
}

export default FreeEbook;
