import React, {useState, useEffect} from 'react'
import './index.scss'
import { Row, Col } from 'antd'
import { notification, Typography, Space, Form, Input, Button, Spin, Steps, Divider  } from 'antd'
import { userData } from "./data";
import * as qs from 'query-string';
import { useLocation, useHistory } from 'react-router';

const { Title, Paragraph } = Typography;
const { Step } = Steps;

interface HistoryType {
	title: string;
	timestamp: string;
}

interface UserInfoType {
	delieveryStatus: string;
	delivered: boolean;
	internalTrackingNum: string;
	thirdPartyTrackNum: string;
	displayAddress: string;
	deliveryHistory: HistoryType[];
  }

function TrackingPage(): JSX.Element {
	const [form] = Form.useForm();
	const location = useLocation();
	const history = useHistory();
	const [, forceUpdate] = useState({});
	const [loading, setLoading] = useState(false);
	const [userInfo, setUserInfo] = useState<UserInfoType>({} as any);
	const [trackNum, setTrackNum] = useState('');
	const [loadedUserData, setLoadedUserData] = useState(userData);

	// To disable submit button at the beginning.
	useEffect(() => {
		forceUpdate({});
	}, []);

	// load num from query string
	useEffect(() => {
		const parsed = qs.parse(location.search);
		if ('num' in parsed){
			setTrackNum(parsed.num as any);
		}else {
			setTrackNum('');
		}
	}, [location]);

	const resetData = () => {
		setUserInfo({} as any);
		const queryParams = new URLSearchParams(location.search)

		if (queryParams.has('num')) {
			queryParams.delete('num')
			history.replace({
				search: queryParams.toString(),
			})
		}

		form.resetFields();
	}

	const filterUserByTrackingNum = async (trackNum: string): Promise<UserInfoType[]> =>{
		await fetch('https://jsonplaceholder.typicode.com/todos/1')
		// setLoadedUserData(userData);
		return loadedUserData.filter(user => user.internalTrackingNum === trackNum.trim());
	}

	// update track num then populate results directly
	useEffect(() => {
		if (trackNum){
			form.setFieldsValue({
				tracking_number: trackNum,
			});

			setLoading(true);

			filterUserByTrackingNum(trackNum)
			.then(matchedUserArr => {
				if (matchedUserArr.length > 0){
					setUserInfo(matchedUserArr[0] as UserInfoType);
				}else {
					resetData();
				}
			}, err => {
				resetData();
			})
			
			setLoading(false);
		}
	}, [trackNum, location, history, resetData, filterUserByTrackingNum]);

	

	const openNotification = () => {
		notification.open({
		  message: 'Sorry, cannot find.',
		  description:
			'This tracking number cannot be found in CodingHiro server, please double check your tracking number. Please contact team@codinghiro.com if you need any help.',
		  className: 'custom-class',
		  style: {
			maxWidth: 600,
		  },
		});
	  };

	const onFinish = async (values: any) => {
		setLoading(true);

		const matchedUserArr = await filterUserByTrackingNum(values.tracking_number);
		
		if (matchedUserArr.length > 0){
			setUserInfo(matchedUserArr[0] as UserInfoType);
		}else {
			resetData();
			openNotification();
		}
		setLoading(false);
	};



	return (
		<div className="tracking-page">
			<section className="search-section white">
				<div className="container">
					<Row>
						<Col span={16} offset={4}>
							<div className="shoutout">
								<Space align="center">
									<Title level={1} className="white">Track an item</Title>
								</Space>
								<Spin tip="Loading..." spinning={loading} delay={500}>
									<Form form={form} size="large" className="tracking-form" name="horizontal_login" layout="inline" onFinish={onFinish}>
										<Form.Item
											name="tracking_number"
											className="tracking-input"
										
											rules={[{ required: true, message: 'Please input your tracking number!' }]}
										>
											<Input  placeholder="Tracking Number" className="border-none"/>
										</Form.Item>
										<Form.Item shouldUpdate>
											{() => (
											<Button
												type="primary"
												htmlType="submit"
												className="tracking-button"
												disabled={
												!form.isFieldsTouched(true) ||
												!!form.getFieldsError().filter(({ errors }) => errors.length).length
												}
											>
												Track
											</Button>
											)}
										</Form.Item>
									</Form>
								</Spin>
							</div>
						</Col>
					</Row>
				</div>
			</section>
			<section className="history-section white">
				{
					Object.entries(userInfo).length !== 0 && 
					<div className="container">
						<Row>
							<Col span={16} offset={4}>
								<div className="shoutout">
									<Space align="center">
										<Title level={2} className="">Your delivery</Title>
									</Space>
									<Paragraph >{userInfo.delieveryStatus}</Paragraph>
									<Paragraph className="line-break">{userInfo.displayAddress}</Paragraph>
									<Divider/>
									<Title level={3} className="">Tracking history</Title>
									{ userInfo.deliveryHistory && <Steps current={userInfo.deliveryHistory.length} progressDot direction="vertical">

										{
											userInfo.deliveryHistory.map((history, index) => 
												<Step title={history.title} description={history.timestamp} key={index} />
											)
										}
									</Steps>
									}
								</div>
							</Col>
						</Row>
					</div>
				}
			</section>
		</div>
	)
}

export default TrackingPage
